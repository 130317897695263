@font-face {
  src: url('/assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  src: url('/assets/fonts/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  src: url('/assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  src: url('/assets/fonts/Poppins-Light.ttf');
}
