$size_14: 14px;
$size_16: 16px;
$text_size_40: 40px;
$size_18: 18px;
$size_12: 12px;
$size_10: 10px;
$size_11: 11px;
$size_24: 24px;
$size_28: 28px;
$size_5: 5px;
$size_50: 50px;
$size_30: 30px;
$size_20: 20px;
$size_3: 3px;
$size_8: 8px;
$size_15: 15px;
$size_6: 6px;
$size_28: 28px;
$size_24: 24px;
$size_40: 40px;
$size_80: 80px;
$size_2: 2px;
$size_0: 0
