@import "./color";
@import "./variables";

@mixin placeholder {
  opacity: 0.4;
  color: $black_secondary;
}

.font-basic {
  font-weight: normal;
  font-stretch: normal;

  line-height: normal;
  letter-spacing: normal;
}

@mixin font-light($font-size: $size_14) {
  @extend .font-basic;
  font-size: $font-size;
}

@mixin font-regular($font-size: $size_14) {
  @extend .font-basic;
  font-size: $font-size;
}

@mixin font-medium($font-size: $size_14) {
  @extend .font-basic;
  font-size: $font-size;
  font-weight: 500;
  line-height: 1.54;
}

@mixin font-semibold($font-size: 14px) {
  @extend .font-basic;
  font-size: $font-size;
}

@mixin gradient-background {
  background-image: linear-gradient(to right, #1f79e2 1%, #1fb2e2, #1f79e2);
}

@mixin upload-btn-bg {
  background-image: linear-gradient(
    to right,
    rgba(31, 121, 226, 0.2) 1%,
    (rgba(31, 178, 226, 0.2)),
    rgba(31, 121, 226, 0.2)
  );
}

@mixin search-btn-bg {
  background-image: linear-gradient(
    to right,
    rgba(31, 121, 226, 0.2) 1%,
    (rgba(31, 178, 226, 0.2)),
    rgba(31, 121, 226, 0.2)
  );
}

@mixin focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #2196f3;
  border: none;
}

@mixin input {
  width: calc(100% - 2px);
  border: none;
  outline: none;
  // padding: 15px;
  height: 40px;
  @include responsive-fourteen-font-size(14px);
  color: $primary;
  border-radius: 5px;
}

@mixin label {
  @include font-regular;
  position: absolute;
  top: -12px;
  left: 10px;
  padding: 0 5px;
  background-color: white;
  color: #404244;
  margin: 0px;
  z-index: 1;
}

@mixin input-container {
  margin-left: 5px;
  margin-top: 20px;
  position: relative;
  min-width: 80%;
  min-height: 20px;
  border: 1.3px solid $text_color_414141;
  border-radius: 3px;
  padding: 1px;
  box-sizing: content-box;

  label {
    @include label;
  }

  input {
    @include input;
    &:focus {
      background-color: white;
    }
  }
}

@mixin notification-icon {
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $primary_main_400;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;

  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
}

@mixin workshop-text {
  font-weight: 400;
  @include responsive-twelve-font-size(12px);
  line-height: 14px;
  color: #191919;
  padding: 5px;
}

@mixin workshop-container-scheduler__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5px;

  .workshop-container-scheduler__header--title {
    font-weight: 700;
    @include responsive-sixteen-font-size(16px);
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
    align-self: center;
  }

  .workshop-container-scheduler__header--current-date {
    display: flex;
    flex-direction: row;

    .workshop-container-scheduler__header--current-date--title {
      font-weight: 500;
      @include responsive-twelve-font-size(12px);
      line-height: 14px;
      color: $secondary_main_400;
      align-self: center;
    }

    .workshop-container-scheduler__header--current-date--value {
      font-weight: 700;
      @include responsive-sixteen-font-size(16px);
      line-height: 19px;
      margin: 0 5px;
      color: rgba(0, 0, 0, 0.87);
      align-self: center;
    }
  }
}

@mixin btn-cancel {
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

@mixin btn-action {
  background: #67aa4a;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  color: #fff;
  @include responsive-sixteen-font-size(16px);
  margin-left: 10px;
  border: none;
}

@mixin site-visit-header(
  $font-size: $size_16,
  $font-weight: 500,
  $font_color: $color_191919
) {
  color: $font_color;
  font-size: $font-size;

  font-weight: $font-weight;
  line-height: normal;
}

// Define a mixin for font size with resolution adjustment
@mixin responsive-sixteen-font-size($font-size) {
  font-size: $font-size;

  @media (min-resolution: 120dpi) {
    font-size: 12px;
  }

  @media (min-resolution: 144dpi) {
    font-size: 10px;
  }
}

// Define a mixin for font size with resolution adjustment
@mixin responsive-twenty-four-font-size($font-size) {
  font-size: $font-size;

  @media (min-resolution: 120dpi) {
    font-size: 14px;
  }

  @media (min-resolution: 144dpi) {
    font-size: 12px;
  }
}

// Define a mixin for font size with resolution adjustment
@mixin responsive-twelve-font-size($font-size) {
  font-size: $font-size;

  @media (min-resolution: 120dpi) {
    font-size: 10px;
  }

  @media (min-resolution: 144dpi) {
    font-size: 8px;
  }
}

// Define a mixin for font size with resolution adjustment
@mixin responsive-fourteen-font-size($font-size) {
  font-size: $font-size;

  @media (min-resolution: 120dpi) {
    font-size: 10px;
  }

  @media (min-resolution: 144dpi) {
    font-size: 8px;
  }
}

@mixin responsive-image-size($width-size) {
  width: $width-size;

  @media (min-resolution: 120dpi) {
    width: 20px;
  }

  @media (min-resolution: 144dpi) {
    width: 18px;
  }
}

@mixin responsive-mat-icon-size($font-size) {
  font-size: $font-size;

  @media (min-resolution: 120dpi) {
    font-size: 20px;
  }

  @media (min-resolution: 144dpi) {
    font-size: 18px;
  }
}
