@import 'src/app/common/styles/_color';
@import 'src/app/common/styles/font';
@import 'src/app/common/styles/variables';

$viewport_width: 114rem;

.icon-arrow-right {
  content: url("/assets/login/path.svg");
  height: 12px;
  width: 30px;
  margin-left: 16px;
}

@mixin name($size: 22px, $color: $text_color_032954) {
  color: $color;
  
  font-size: $size;
}

@mixin profile-box {
  .profile-title {
    color: $color_707070;
    font-size: $size_14;
    font-weight: 500;
  }

  .profile-value {
    color: $text_color_032954;
    font-size: $size_16;
    font-weight: 500;
  }
}


@mixin btn-common($family-value: 'Poppins-SemiBold') {
  background: linear-gradient(to right, $primary_blue_400, $txt_color_1fb2e2);
  letter-spacing: 0;
  color: $white;
  text-transform: uppercase;
  outline: none;
  border: transparent;
}

@mixin input-box {
    height: $size_50;
    width: 75%;
    margin-left: 5px;
    margin-top: $size_40;
    position: relative;

    label {
      display: block;
      position: absolute;
      top: -15px;
      width: fit-content;
      left: $size_10;
      padding: 0 $size_5;
      background-color: white;
      font-size: $size_14;
      color: $color_404244;
    }

    input {
      border: 1px black;
      outline: none;
      padding-left: 5px;
      @include responsive-fourteen-font-size(14px);
      opacity: 0.4;
      width: 98%;
      color: $primary;
      position: absolute;
      top: 30%;
      box-shadow: 0 $white;
    }
  }


.title {
  color: $text_color_032954;
  font-size: $size_28;
  margin-left: 2%;
  padding-left: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 9px;
  border: solid 2px #1f79e2;
}
