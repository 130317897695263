$primary: #68B963;
$primary_main_400:#67AA4A;
$secondary_main_500:#505050;
$secondary_main_400:#999999;
$secondary_main_300:#B3B3B3;
$secondary_main_50:#F7F7F7;
$secondary_main_100:#CCCCCC;
$warn_main_400:#FFAB00;
$warn_main_50:#FFF7E5;
$error_main_400:#BF2600;
$error_main_50:#FFEFEB;
$success_main_400:#338C1C;
$success_main_50:#F2FFEF;
$primary_blue_400: #1665DB;
$primary_blue_50: #DEE9FA;
$primary_blue_40: #F5F9FF;
$text_color_3b3b3b: #3b3b3b;
$text_color_414141: #414141;
$text_color_032954: #032954;
$color_white: #fff;
$txt_color_061058: #061058;
$txt_color_1fb2e2: #1fb2e2;
$text_color_061058: #061058;
$white : #ffffff;
$black_primary : #191919;
$black_secondary : #222222;
$text_primary: #061058;
$text_color_f5f6fa: #f5f6fa;
$red : #E91E63;
$color_1f79e2: #1f79e2;
$color_1fb2e2: #1fb2e2;
$text_color_707070: #707070;
$color_404244: #404244;
$color_061058: #061058;
$color_414141: #414141;
$color_3b3b3b: #3b3b3b;
$color_FFD2D2: #FFD2D2;
$color_D8000C: #D8000C;
$color_0000001f: #0000001f;
$color_707070: #707070;
$color_999999: $secondary_main_400;
$color_eee: #eee;
$color_73a7e4: #73a7e4;
$color_39c75b: #39c75b;
$color_39C75B: #39C75B;
$color_fdfdfd: #FDFDFD;
$color_f5f6fa: #f5f6fa; //bg color
$color_67AA4A: #67AA4A;
$app_bg: #F3F3F3;
$color_1665D8: #1665D8;
$color_191919: #191919;
$box_shadow:#00000014;
$danger:#D8000C;
$color_000000:#000000;
$warn:rgb(198, 198, 0);
$color_FFAB00: #FFAB00;
$color_FFF7E5: #FFF7E5;
$color_F7F7F7: #F7F7F7;
$color_CCC: #CCC;
$color_505050: #505050;
$color_338C1C: #338C1C;
$color_F2FFEF: #F2FFEF;
$color_white_main:#FFF;
